import { module as BaseModule} from 'modujs';
import modularLoad from "../modularLoad";
import { body } from '../utils/environment';


export default class extends BaseModule {
  constructor(m) {
    super(m);
    // this.navigationElementHeight = document.querySelector(".c-header-navigation").offsetHeight;

  }

  init() {
    const load = new modularLoad({
      // enterDelay: 3000,
      isSPA: false,
      // transitions: {
      //   customTransition: {},
      // },
    });

    this.setSizes();
    



    //spa only

    //   this.preload();

    //   this.load.on('loading', (transition, oldContainer) => {
    //     html.classList.remove('has-dom-ready');
    //     html.classList.remove('has-dom-ready-callback');
    //     html.classList.remove('has-dom-animated');

    //     html.classList.add('has-transition');
    //     html.classList.add('has-transition-full');

    //     if(window.Snipcart != undefined) {
    //         window.Snipcart.api.theme.cart.close()
    //     }
    // });

    // this.load.on('loaded', (transition, oldContainer, newContainer) => {
    //     this.call('destroy', oldContainer, 'app');
    //     this.call('update', newContainer, 'app');

    //     window.scrollInstance.scroll = 0;

    //     this.call('refresh',newContainer.getAttribute('data-id'),'Nav');

    //     gsap.delayedCall(window.readyDelay,() => {

    //         html.classList.remove('has-transition');
    //         html.classList.add('has-dom-ready');

    //         body.setAttribute('class',newContainer.getAttribute('class'));
    //         html.setAttribute('data-template',newContainer.getAttribute('data-template'));

    //         gsap.delayedCall(window.readyCallbackDelay,() => {
    //             html.classList.add('has-dom-ready-callback');
    //             html.classList.remove('has-transition-full');

    //             gsap.delayedCall(1,() => {
    //                 html.classList.add('has-dom-animated');
    //             });

    //         });                
    //     })

    //     this.preload();
    // });


  }

  preload() {
    // preload the current href
    const getFirstTpl = document.querySelector("[data-load-container]").dataset
      .template;

    if (window.firstHit == false) {
      let url = window.location.href;
      if (getFirstTpl == "home") {
        url = url.replace(/\/$/, "");
      }

      this.load.preloadHref(url);
    }

    this.$preloadLinks = Array.from(
      this.el.querySelectorAll("[data-load-preload]")
    );
    this.$preloadLinks.forEach(($link) => {
      this.load.preloadHref($link.getAttribute("href"));
    });
  }


  
  setSizes() {


    if (window.innerHeight - document.documentElement.clientHeight > 0) {
      body.classList.add('has-scrollbar-y');
      document.documentElement.style.setProperty('--scrollbar', `${window.innerHeight - document.documentElement.clientHeight}px`);
    } else {
      body.classList.remove('has-scrollbar-y');
    }

    // screen height
    document.documentElement.style.setProperty('--app-availheight', `${window.screen.availHeight}px`);
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    document.documentElement.style.setProperty('--app-height-negative', `-${window.innerHeight}px`);
    document.documentElement.style.setProperty('--height-of-nav', `${document.querySelector(".c-header-navigation").offsetHeight}px`);
    



    window.addEventListener('resize', () => {
      if (!window.isMobile) {
        document.documentElement.style.setProperty('--app-availheight', `${window.screen.availHeight}px`);
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
        document.documentElement.style.setProperty('--app-height-negative', `-${window.innerHeight}px`);
        document.documentElement.style.setProperty('--height-of-nav', `${document.querySelector(".c-header-navigation").offsetHeight}px`);
        
        
        
      } 
    });
  }




}
