import { module as BaseModule } from 'modujs';
// import LocomotiveScroll from "locomotive-scroll";
import Lenis from '@studio-freight/lenis';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { html } from "../utils/environment";
gsap.registerPlugin(ScrollTrigger);

// data-attributes
// data-scroll="item" : to add element as "detectable"
// data-scroll="to" with `href` or `data-href` to add a scrollTo link

// ScrollTo options
//data-scroll-to-duration
//data-scroll-to-offset
//data-scroll-to-immediate

// data-scroll-id

// Call example : (data-scroll-call, data-scroll-progress-call)
// data-scroll-call="function,moduleName,{moduleId}" - call a function, in the modue "moduleName" with id the id "moduleId"
// OR
// data-scroll-id="myCustomId" data-scroll-call="function,moduleName"  will automatically call myModule with the id : "myCustomId"

// data-scroll-progress-call="function,moduleName,{moduleId}" - call a function on element in viewport updates, in the modue "moduleName" with id the id "moduleId"
// data-scroll-progress-reverse will call progress method from 1 to 0
// data-scroll-progress-css - will set a property --progress to get a css variable on your desired element

// data-scroll-start refered to `start` value of scrollTrigger
// data-scroll-end refered to `end` value of scrollTrigger
// data-scroll-marker 
// data-scroll-repeat
// data-scroll-target - trigger when the target element is triggered

// data-scroll="item"
// data-module-timeline="headerCollection"
// data-scroll-progress-call="progress,Timeline,headerCollection"
// data-timeline-from='{"y":"0"}'
// data-timeline-to='{"y":"20%"}'
// data-scroll-start="top top"


// data-scroll="item"
// data-scroll-parallax-from="-30%"
// data-scroll-parallax-to="0"
// data-scroll-start="-50% bottom" 
// data-scroll-end="top 20%"


const IN_VIEW_CLASS = 'is-inview';

export default class extends BaseModule {
  constructor(m) {
    super(m);

    this.$scrollItems = Array.from(this.$('item'));

    // check if items are outside the container
    this.$scrollOutsideItems = document.querySelectorAll('[data-scroll="outsideItem"]');
    this.$scrollOutsideItems.forEach((outsideItem) => {
        if(outsideItem.getAttribute('data-scroll-parent') == this.el.getAttribute('data-module-scroll')) {
            this.$scrollItems.push(outsideItem);
        }
    });

    this.$scrollToWip = this.$('wip')[0];
    this.scrollToItems = this.$('to');
    this.$wrapper = typeof this.getData('wrapper') == 'string' ? document.querySelector(this.getData('wrapper')) : window;
    this.direction = typeof this.getData('direction') == 'string' ? this.getData('direction') : 'vertical';

    this.events = {
        'click': {
            to: 'scrollTo'
        }
    };

   

}

init() {
    
    this.windowWidth = window.innerWidth;

    if(window.isMobile) {
        this.gestureDirection = 'vertical';
        this.direction = 'vertical';
    } 
    
    if(window.isTablet) {
        this.direction = 'vertical';
        this.gestureDirection = 'vertical';
    }

    window.scrollInstance = {
        velocity: 0,
        direction: 'down',
        progress: 0
    };

    html.setAttribute('data-scroll-direction',this.direction);
    this.initContainerSize();
    this.imagesLoading();

    this.id = this.el.getAttribute('data-module-scroll');

    this.lenis = new Lenis({
        wrapper: this.$wrapper,
        content: document.documentElement,
        orientation: this.direction,
        gestureOrientation: this.gestureDirection,
        smoothWheel: false,
        smoothTouch: false,
        duration: 1.2,
        lerp: 0.1,
        wheelMultiplier: 0.7,
        touchMultiplier: 1.7,
        touchInertiaMultiplier: 12
    });
    
    //get scroll value
    this.lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
        if(this.id == 'main') {
            
            window.scrollInstance = { 
                scroll,
                velocity,
                direction: velocity >= 0 ? 'down' : 'up', 
                progress
            };


           
        }
      
   
         
    });

    


  

    this.raf(0);

    // this.$wrapper.scrollTo(0,0);

    // Init elements to detect
    gsap.delayedCall(window.readyDelay,() => {
        this.initElements();

    });
    gsap.delayedCall(window.readyCallbackDelay,() => {
        this.refresh();

    });

    gsap.delayedCall(window.readyCallbackDelay*2,() => {
        this.refresh();                        
                    
        if(window.location.hash != '') {
            this.lenis.scrollTo(document.querySelector(window.location.hash));
        }

        if(this.$scrollToWip != undefined) {
            this.lenis.scrollTo(this.$scrollToWip);
        }
    });

    this.bindResize = this.resize.bind(this);
    window.addEventListener('resize', this.bindResize);


    // this.buttonScroll = document.querySelector('[data-page="overview"] .c-iso-search .c-jobs-search-box__button-search');

    // if(this.buttonScroll) {
    //     this.buttonScroll.addEventListener('click', (e) => {
    //         e.preventDefault();
    //         this.scrollToElement('#job-list');
    //     });
    // }

    
}

initContainerSize() {
    if (this.direction === 'horizontal') {
        let elWidth = 0;

        for (let childIndex = 0; childIndex < this.el.children.length; childIndex++) {
            const child = this.el.children[childIndex];
            elWidth += child.getBoundingClientRect().width;
        }
        this.el.style.setProperty('--scrollContainerWidth', elWidth + 'px');
    }
}

imagesLoading() {
    let $images = Array.from(document.getElementsByTagName('img'));
    let count = 0;

    $images.forEach($image => {

        $image.addEventListener('load',() => {
            count++;
            console.log(`${count}/${$images.length}`);

            if(count == $images.length) {
                this.initContainerSize();
            }
        });
    });
}

initElements() {

    this.triggers = new Array(this.$scrollItems.length);
    this.scrollElements = new Array(this.$scrollItems.length);

    this.$scrollItems.forEach(($element, index) => {

        let scrollElement = this.createElement($element, index);
        let hasMarkers = typeof $element.getAttribute('data-scroll-marker') == 'string';
        let $target = typeof $element.getAttribute('data-scroll-target') == 'string' ? document.querySelector($element.getAttribute('data-scroll-target')) : $element;

        let options = {
            scroller: this.$wrapper,
            trigger: $target,
            start: typeof $element.getAttribute('data-scroll-start') == 'string' ? $element.getAttribute('data-scroll-start') : 'top bottom',
            end: typeof $element.getAttribute('data-scroll-end') == 'string' ? $element.getAttribute('data-scroll-end') : 'bottom top',
            markers: hasMarkers,    
            horizontal: this.direction == 'horizontal',
            onEnter: () => {
                this.onElementEnter(scrollElement,'down');
            },
            onLeave: () => {
                this.onElementLeave(scrollElement,'down');
            },
            onEnterBack: () => {
                this.onElementEnter(scrollElement,'up');
            },
            onLeaveBack: () => {
                this.onElementLeave(scrollElement,'up');
            }
        };            
        if(scrollElement.progressCallParameters != undefined) {
            options = {...options, 
                onUpdate: (self) => {
                    this.onElementProgress(self, scrollElement);
                }
            };
        } 
        
        if(typeof $element.getAttribute('data-scroll-progress-css') == 'string') {
            $element.style.setProperty('--progress',0);

            options = {...options, 
                onUpdate: (self) => {
                    let progress = self.progress.toFixed(3);        
                    $element.style.setProperty('--progress',progress);
                }
            };
        }

        if(scrollElement.parallaxTl != undefined) {
            options = {...options, 
                onUpdate: (self) => {
                    scrollElement.parallaxTl.progress(self.progress);
                }
            };
        }

        let sTrigger = ScrollTrigger.create(options);

        this.scrollElements.push({
            ...scrollElement,
            sTrigger
        });
    });
}

createElement($element, index) {
    let id = $element.getAttribute('data-scroll-id');
    let isRepeatable = typeof $element.getAttribute('data-scroll-repeat') == 'string';
    
    // Manage classic call
    let callParameters;
    if(typeof $element.getAttribute('data-scroll-call') === 'string') {

        let callParametersArray = $element.getAttribute('data-scroll-call').split(',');
        callParameters = {
            function: callParametersArray[0],
            module: callParametersArray[1],
            moduleId: (callParametersArray.length < 3) ? id : callParametersArray[2]
        };
        if(callParameters.moduleId == undefined) {
            console.warn(`You didn't specified a data-scroll-id, or a moduleId in your data-scroll-call`,$element);
        }
    } else {
        callParameters = undefined;
    }

    // Manage progress call
    let progressCallParameters;
    if(typeof $element.getAttribute('data-scroll-progress-call') === 'string') {
        
        let progressCallParametersArray = $element.getAttribute('data-scroll-progress-call').split(',');

        progressCallParameters = {
            function: progressCallParametersArray[0],
            module: progressCallParametersArray[1],
            moduleId: (progressCallParametersArray.length < 3) ? id : progressCallParametersArray[2],
            isReversed: typeof $element.getAttribute('data-scroll-progress-reverse') == 'string'
        };
        if(progressCallParameters.moduleId == undefined) {
            console.warn(`You didn't specified a data-scroll-id, or a moduleId in your data-scroll-progress-call`, this.el);
        }
    } else {
        progressCallParameters = undefined;
    }

    let parallax = typeof $element.getAttribute('data-scroll-parallax-from') === 'string';
    let parallaxTl;

    if(parallax) {
        parallaxTl = gsap.fromTo($element,{
            y: $element.getAttribute('data-scroll-parallax-from')
        },{
            y: $element.getAttribute('data-scroll-parallax-to'),
            duration: 1,
            ease: 'none'
        });
        parallaxTl.pause();
    }

    return {
        $el: $element,
        id,
        isRepeatable,
        callParameters,
        parallaxTl,
        progressCallParameters
    };
}

onElementProgress(self, scrollElement) {
    
    let progress = self.progress.toFixed(3);        
    
    this.call(scrollElement.progressCallParameters.function,
        {
            progress:parseFloat(progress),
            isReversed: scrollElement.progressCallParameters.isReversed
        },
        scrollElement.progressCallParameters.module,
        scrollElement.progressCallParameters.moduleId
    );
}

onElementEnter(scrollElement, direction) {
    if(!scrollElement.isRepeatable && scrollElement.$el.classList.contains(IN_VIEW_CLASS)) {
        return;
    }
    scrollElement.$el.classList.add(IN_VIEW_CLASS);

    // Call js functions in a specific module with data-attribute
    if(scrollElement.callParameters != undefined) {
        this.call(scrollElement.callParameters.function,{mode: 'enter', direction: direction, $el: scrollElement.$el},scrollElement.callParameters.module, scrollElement.callParameters.moduleId);
    }
}

onElementLeave(scrollElement, direction) {
    if(!scrollElement.isRepeatable && scrollElement.$el.classList.contains(IN_VIEW_CLASS)) {
        return;
    }
    scrollElement.$el.classList.remove(IN_VIEW_CLASS);
    
    // Call js functions in a specific module with data-attribute
    if(scrollElement.callParameters != undefined) {
        this.call(scrollElement.callParameters.function,{mode: 'leave', direction: direction, $el: scrollElement.$el},scrollElement.callParameters.module, scrollElement.callParameters.moduleId);
    }
}

raf(time) {
    this.lenis.raf(time);
    this.rafInstance = requestAnimationFrame((time) => this.raf(time));

    if(this.lenis.scroll > 100) {
        if(!html.classList.contains('has-scrolled')) {
            html.classList.add('has-scrolled');
        }
    } else {
        if(html.classList.contains('has-scrolled')) {
            html.classList.remove('has-scrolled');
        }
    }

if(this.lenis.scroll > 100) {
    
     // Check direction
     html.classList.toggle("nav-hidden", this.lenis.direction === 1);
     // Check progress
     html.classList.toggle("has-scrolled-bottom", this.lenis.progress === 1);
}



    
}



scrollToElement(target) {
    const targetElement = document.querySelector(target);
    let duration = 1.2; // You can adjust the duration as needed
    let offset = 0; // You can adjust the offset as needed
    let immediate = true; // Set to true if you want the scroll to happen immediately
    let easing = (x) => x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;

    if (targetElement) {
        this.lenis.scrollTo(targetElement, {
            offset,
            duration,
            immediate,
            easing
        });
    }
}



scrollTo(e) {
    e.preventDefault();
    let target = typeof e.currentTarget.getAttribute('href') === 'string' ? e.currentTarget.getAttribute('href') : e.currentTarget.getAttribute('data-href');
    let duration = typeof e.currentTarget.getAttribute('data-scroll-to-duration') == 'string' ? parseFloat(e.currentTarget.getAttribute('data-scroll-to-duration')) : 2;
    let offset = typeof e.currentTarget.getAttribute('data-scroll-to-offset') == 'string' ? parseInt(e.currentTarget.getAttribute('data-scroll-to-offset')) : 0;
    let immediate = typeof e.currentTarget.getAttribute('data-scroll-to-immediate') == 'string';
    let easing = (x) => x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;

    this.lenis.scrollTo(target,{
        offset,
        duration,
        immediate,
        easing
    });
}

refresh() {
    if(this.scrollElements != undefined) {
        this.scrollElements.forEach(scrollElement => {
            scrollElement.sTrigger.refresh();
        });
    }
}

resize() {
    
    if(this.resizeTimeout != undefined) {
        this.resizeTimeout.kill();
    }
    this.resizeTimeout = gsap.delayedCall(0.1, () => {
        this.resizeDebounce();
    });
    
}

resizeDebounce() {
    if(this.windowWidth != window.innerWidth ||
    (this.direction == 'horizontal' && !window.isMobile)) {
        this.initContainerSize();
        this.windowWidth = window.innerWidth;
        this.refresh();
    }
}

destroy() {
    this.scrollElements.forEach(scrollElement => {
        scrollElement.sTrigger.kill();
    });
    cancelAnimationFrame(this.rafInstance);

    window.removeEventListener('resize', this.bindResize);
}
}


