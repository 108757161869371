import { module as BaseModule } from 'modujs';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.min.css';


export default class extends BaseModule {
  constructor(m) {
    super(m);
    

    this.events = {
      click: {
        prev: 'prev',
        next: 'next'
      }
    };
  }

  init() {

    this.swiper = new Swiper(this.$('slider')[0], {
     
        // slidesPerView: 3.4, 
        // centeredSlides: false,
        // loop: true,
        // freeMode: true,
        // spaceBetween: 30,

        slidesPerView: 1.5,
        loop: true,
        slideToClickedSlide: false,
        centeredSlides: true,
        spaceBetween: 30,

  
        
        
        breakpoints: {
           
            1000: {
                slidesPerView: 3.4,
                centeredSlides: false,
  loop: true,
  freeMode: true,
  spaceBetween: 30,
        
            },

           
        //     500: {
        //       slidesPerView: 1.5, 
        // centeredSlides: false,
        // loop: true,
        // freeMode: true,
        // spaceBetween: 30,
        
                
        //     }
           
        }
    });

    this.swiper.on('slideChange', (e) => {

        if(this.swiper.isEnd) {
            this.el.classList.add('is-end');
        } else {
            this.el.classList.remove('is-end');
        }

        if(this.swiper.isBeginning) {
            this.el.classList.add('is-beginning');
        } else {
            this.el.classList.remove('is-beginning');
        }
    });

    if(this.swiper.isEnd) {
        this.el.classList.add('is-end');
    } else {
        this.el.classList.remove('is-end');
    }

    if(this.swiper.isBeginning) {
        this.el.classList.add('is-beginning');
    } else {
        this.el.classList.remove('is-beginning');
    }
}

prev() {
    this.swiper.slidePrev();
}

next() {
    this.swiper.slideNext();
}
}
