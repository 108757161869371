import { module as BaseModule } from 'modujs';
import { gsap } from 'gsap';

export default class extends BaseModule {
  constructor(m) {
    super(m);
  }

  init() {
    var Accordion = (function () {
      var toggleItems, items;

      var _init = function () {
        toggleItems = document.querySelectorAll(".c-accordion__item");
        toggleItems = Array.prototype.slice.call(toggleItems);
        items = document.querySelectorAll(".c-accordion__item--click");
        items = Array.prototype.slice.call(items);

        _addEventHandlers();
        gsap.set(items, {
          visibility: "visible",
        });
      };

      var _addEventHandlers = function () {
        items.forEach(function (element) {
          // Set up initial aria attributes
          element.setAttribute("role", "button");
          element.setAttribute("tabindex", "0");
          element.setAttribute("aria-expanded", "false");

          // Toggle on click and keyboard interaction
          element.addEventListener("click", _toggleItem, false);
          element.addEventListener("keydown", function (e) {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              _toggleItem.call(element);
            }
          });
        });
      };

      var _toggleItem = function () {
        var parent = this.parentNode;
        var content = parent.querySelector(".c-accordion__bottom");
        var isExpanded = this.getAttribute("aria-expanded") === "true";

        // Update aria-expanded based on current state
        this.setAttribute("aria-expanded", !isExpanded);
        parent.classList.toggle("is-active", !isExpanded);

        if (!isExpanded) {
          gsap.set(content, { height: "auto" });
          gsap.from(content, {
            height: 0,
            duration: 0.5,
            immediateRender: false,
            ease: "Back.easeOut",
          });

          if (parent.classList.contains("-has-inner-accordion")) {
            gsap.to(content, { height: "auto" });
          }
        } else {
          gsap.to(content, {
            height: 0,
            duration: 0.3,
            immediateRender: false,
            ease: "Power1.easeOut",
          });
        }
      };

      return {
        init: _init,
      };
    })();

    Accordion.init();
  }
}
