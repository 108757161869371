// import "normalize.css";
import "../styles/main.scss";

import modular from "modujs";
import * as modules from "./modules";
import globals from "./globals";
import { html } from "./utils/environment";

const welcomeMessageStyles = 'background: #000000; padding: 10px 0; color: #ffffff;';
const welcomeMessage = '%c 👋 Hey! This website is powered by https://paradox.ai \n';

class Zxni {
  constructor() {
    html.classList.add("is-loading");
    document.addEventListener("DOMContentLoaded", this.init.bind(this));
  }

  init() {
    const app = new modular({ modules });
    app.init(app);
    globals();
    html.classList.add("is-loaded", "is-ready");
    html.classList.remove("is-loading");
  }
}

// IIFE for loading the application
// ==========================================================================
(function () {
  console.log(welcomeMessage, welcomeMessageStyles);
  new Zxni();
})();
