
const support = {
  animations: Modernizr.cssanimations,
};

function onEndAnimation(el, callback) {
  const animEndEventNames = {
    WebkitAnimation: "webkitAnimationEnd",
    OAnimation: "oAnimationEnd",
    msAnimation: "MSAnimationEnd",
    animation: "animationend",
  };
  const animEndEventName = animEndEventNames[Modernizr.prefixed("animation")];

  const onEndCallbackFn = function (ev) {
    if (support.animations) {
      if (ev.target != this) return;
      this.removeEventListener(animEndEventName, onEndCallbackFn);
    }
    if (callback && typeof callback === "function") {
      callback.call();
    }
  };

  if (support.animations) {
    el.addEventListener(animEndEventName, onEndCallbackFn);
  } else {
    onEndCallbackFn();
  }
}

function extend(a, b) {
  for (const key in b) {
    if (b.hasOwnProperty(key)) {
      a[key] = b[key];
    }
  }
  return a;
}

export default class {
  constructor(options) {
    this.defaultOptions = {
      breadcrumbsCtrl: true,
      initialBreadcrumb: "all",
      el: '#c-mega-menu',
      backCtrl: true,
      itemsDelayInterval: 60,
      direction: "r2l",
      onItemClick: function (ev, itemName) {
        return false;
      },
    };
  
    this.options = extend({}, this.defaultOptions);
    extend(this.options, options);

    this.el = document.querySelector(this.options.el);

    this.menus = [].slice.call(this.el.querySelectorAll(".c-mega-menu__level"));
    this.current_menu = 0;

    var current_menu;
    this.menus.forEach((menuEl, pos) => {
      var items = menuEl.querySelectorAll(".c-mega-menu__item");
      items.forEach((itemEl, iPos) => {
        var currentLink = itemEl.querySelector(".c-mega-menu__item-link--current");
        if (currentLink) {
          current_menu = pos;
        }
      });
    });

    if (current_menu) {
      this.current_menu = current_menu;
    }

    this.init();
  }



  init() {
    this.menusArr = [];
    this.breadCrumbs = false;
    var self = this;
    var submenus = [];

    this.menus.forEach((menuEl, pos) => {
      var menu = {
        menuEl: menuEl,
        menuItems: [].slice.call(menuEl.querySelectorAll(".c-mega-menu__item")),
      };

      self.menusArr.push(menu);

      if (pos === self.current_menu) {
        menuEl.classList.add("c-mega-menu__level--current");
      }

      var menu_x = menuEl.getAttribute("data-menu");
      var links = menuEl.querySelectorAll(".c-mega-menu__item-link");
      links.forEach((linkEl, lPos) => {
        var submenu = linkEl.getAttribute("data-submenu");
        if (submenu) {
          var pushMe = {
            menu: submenu,
            name: linkEl.innerHTML,
          };
          if (submenus[pos]) {
            submenus[pos].push(pushMe);
          } else {
            submenus[pos] = [];
            submenus[pos].push(pushMe);
          }
        }
      });
    });

    this.menus.forEach((menuEl, pos) => {
      var menu_x = menuEl.getAttribute("data-menu");
      submenus.forEach((subMenuEl, menu_root) => {
        subMenuEl.forEach((subMenuItem, subPos) => {
          if (subMenuItem.menu == menu_x) {
            self.menusArr[pos].backIdx = menu_root;
            self.menusArr[pos].name = subMenuItem.name;
          }
        });
      });
    });

    if (self.options.breadcrumbsCtrl) {
      this.breadcrumbsCtrl = document.createElement("nav");
      this.breadcrumbsCtrl.className = "c-mega-menu__breadcrumbs";
      this.breadcrumbsCtrl.setAttribute("aria-label", "Bread Crumbs");
      this.el.insertBefore(this.breadcrumbsCtrl, this.el.firstChild);
      this._addBreadcrumb(0);

      if (self.menusArr[self.current_menu].backIdx != 0 && self.current_menu != 0) {
        this._crawlCrumbs(self.menusArr[self.current_menu].backIdx, self.menusArr);
        this.breadCrumbs = true;
      }

      if (self.current_menu != 0) {
        this._addBreadcrumb(self.current_menu);
        this.breadCrumbs = true;
      }
    }

    if (this.options.backCtrl) {
      this.backCtrl = document.createElement("button");
      if (this.breadCrumbs) {
        this.backCtrl.className = "c-mega-menu__back";
      } else {
        this.backCtrl.className = "c-mega-menu__back c-mega-menu__back--hidden";
      }
      this.backCtrl.setAttribute("aria-label", "Go back");
      this.backCtrl.innerHTML = '<span class="c-mega-menu__back-label">Back</span>';
      this.backCtrl.setAttribute("aria-label", "Back");
      this.backCtrl.setAttribute("tabindex", "0");

      this.el.insertBefore(this.backCtrl, this.el.firstChild);
    }

    this._initEvents();
  }

  _initEvents() {
    var self = this;

    for (var i = 0, len = this.menusArr.length; i < len; ++i) {
      this.menusArr[i].menuItems.forEach((item, pos) => {
        item.querySelector("a").addEventListener("click", function (ev) {
          var submenu = ev.target.getAttribute("data-submenu"),
            itemName = ev.target.innerHTML,
            subMenuEl = self.el.querySelector('ul[data-menu="' + submenu + '"]');

          if (submenu && subMenuEl) {
            ev.preventDefault();
            self._openSubMenu(subMenuEl, pos, itemName);
          } else {
            var currentlink = self.el.querySelector(".c-mega-menu__item-link--current");
            if (currentlink) {
              currentlink.classList.remove("c-mega-menu__item-link--current");
            }
            ev.target.classList.add("c-mega-menu__item-link--current");
            self.options.onItemClick(ev, itemName);
          }
        });
      });
    }

    if (this.options.backCtrl) {
      this.backCtrl.addEventListener("click", function () {
        self._back();
      });
    }
  }

  _openSubMenu(subMenuEl, clickPosition, subMenuName) {
    if (this.isAnimating) {
      return false;
    }
    this.isAnimating = true;

    this.menusArr[this.menus.indexOf(subMenuEl)].backIdx = this.current_menu;
    this.menusArr[this.menus.indexOf(subMenuEl)].name = subMenuName;
    this._menuOut(clickPosition);
    this._menuIn(subMenuEl, clickPosition);
  }

  _back() {
    if (this.isAnimating) {
      return false;
    }
    this.isAnimating = true;

    this._menuOut();
    var backMenu = this.menusArr[this.menusArr[this.current_menu].backIdx].menuEl;
    this._menuIn(backMenu);

    if (this.options.breadcrumbsCtrl) {
      this.breadcrumbsCtrl.removeChild(this.breadcrumbsCtrl.lastElementChild);
    }
  }

  _menuOut(clickPosition) {
    var self = this,
      currentMenu = this.menusArr[this.current_menu].menuEl,
      isBackNavigation = typeof clickPosition == "undefined" ? true : false;

    this.menusArr[this.current_menu].menuItems.forEach((item, pos) => {
      item.style.WebkitAnimationDelay = item.style.animationDelay = isBackNavigation ?
        parseInt(pos * self.options.itemsDelayInterval) + "ms" :
        parseInt(Math.abs(clickPosition - pos) * self.options.itemsDelayInterval) + "ms";
    });

    if (this.options.direction === "r2l") {
      currentMenu.classList.add(!isBackNavigation ? "animate-outToLeft" : "animate-outToRight");
    } else {
      currentMenu.classList.add(isBackNavigation ? "animate-outToLeft" : "animate-outToRight");
    }
  }

  _menuIn(nextMenuEl, clickPosition) {
    var self = this,
      currentMenu = this.menusArr[this.current_menu].menuEl,
      isBackNavigation = typeof clickPosition == "undefined" ? true : false,
      nextMenuIdx = this.menus.indexOf(nextMenuEl),
      nextMenu = this.menusArr[nextMenuIdx],
      nextMenuEl = nextMenu.menuEl,
      nextMenuItems = nextMenu.menuItems,
      nextMenuItemsTotal = nextMenuItems.length;

    nextMenuItems.forEach((item, pos) => {
      item.style.WebkitAnimationDelay = item.style.animationDelay = isBackNavigation ?
        parseInt(pos * self.options.itemsDelayInterval) + "ms" :
        parseInt(Math.abs(clickPosition - pos) * self.options.itemsDelayInterval) + "ms";

      var farthestIdx = clickPosition <= nextMenuItemsTotal / 2 || isBackNavigation ?
        nextMenuItemsTotal - 1 :
        0;

      if (pos === farthestIdx) {
        onEndAnimation(item, function () {
          if (self.options.direction === "r2l") {
            currentMenu.classList.remove(!isBackNavigation ? "animate-outToLeft" : "animate-outToRight");
            nextMenuEl.classList.remove(!isBackNavigation ? "animate-inFromRight" : "animate-inFromLeft");
          } else {
            currentMenu.classList.remove(isBackNavigation ? "animate-outToLeft" : "animate-outToRight");
            nextMenuEl.classList.remove(isBackNavigation ? "animate-inFromRight" : "animate-inFromLeft");
          }
          currentMenu.classList.remove("c-mega-menu__level--current");
          nextMenuEl.classList.add("c-mega-menu__level--current");

          self.current_menu = nextMenuIdx;

          if (!isBackNavigation) {
            if (self.options.backCtrl) {
              self.backCtrl.classList.remove("c-mega-menu__back--hidden");
            }
            self._addBreadcrumb(nextMenuIdx);
          } else if (self.current_menu === 0 && self.options.backCtrl) {
            self.backCtrl.classList.add("c-mega-menu__back--hidden");
          }

          self.isAnimating = false;

          nextMenuEl.focus();
        });
      }
    });

    if (this.options.direction === "r2l") {
      nextMenuEl.classList.add(!isBackNavigation ? "animate-inFromRight" : "animate-inFromLeft");
    } else {
      nextMenuEl.classList.add(isBackNavigation ? "animate-inFromRight" : "animate-inFromLeft");
    }
  }

  _addBreadcrumb(idx) {
    if (!this.options.breadcrumbsCtrl) {
      return false;
    }

    var bc = document.createElement("a");
    bc.href = "#";
    bc.innerHTML = idx ? this.menusArr[idx].name : this.options.initialBreadcrumb;
    this.breadcrumbsCtrl.appendChild(bc);

    var self = this;
    bc.addEventListener("click", function (ev) {
      ev.preventDefault();

      if (!bc.nextSibling || self.isAnimating) {
        return false;
      }
      self.isAnimating = true;

      self._menuOut();
      var nextMenu = self.menusArr[idx].menuEl;
      self._menuIn(nextMenu);

      var siblingNode;
      while ((siblingNode = bc.nextSibling)) {
        self.breadcrumbsCtrl.removeChild(siblingNode);
      }
    });
  }

  _crawlCrumbs(currentMenu, menuArray) {
    if (menuArray[currentMenu].backIdx != 0) {
      this._crawlCrumbs(menuArray[currentMenu].backIdx, menuArray);
    }
    this._addBreadcrumb(currentMenu);
  }
}


