import {
  module as BaseModule
} from 'modujs';

import Modernizr from "../vendors/modernizr";
import MLMenu from '../MLMenu';

export default class extends BaseModule {
  constructor(m) {
    super(m);
  }

  init() {
    // this.initLangDropdowns();
    // this.configureBreadcrumbs();
    this.initMenu();
    this.ADANavigation();
  }

  initMenu() {
    const mlmenu = new MLMenu({
      el: '#c-mega-menu'
    });

    // mobile menu toggle
    const openMenuCtrl = document.querySelector(".c-header-navigation__button");
    const headerComponent = document.querySelector(".c-header-navigation");
    const megaMenuLevels = document.querySelectorAll('.c-mega-menu__level');

    openMenuCtrl.addEventListener("click", function () {
      const element = document.querySelector(".c-header-navigation");
      const expanded = this.getAttribute('aria-expanded') === 'true';
      this.setAttribute('aria-expanded', !expanded);

      if (element.classList.contains("has-nav-open")) {
        closeMenu();
      } else {
        openMenu();
      }
    });

    window.addEventListener('resize', () => {
      let mql = window.matchMedia("(min-width: 1000px)");

      if (headerComponent.classList.contains("has-nav-open") && mql) {
        closeMenu();
      }
    });

    function openMenu() {
      headerComponent.classList.add("has-nav-open");
      document.documentElement.classList.add("-oh");
    }

    function closeMenu() {
      headerComponent.classList.remove("has-nav-open");
      document.documentElement.classList.remove("-oh");
    }

    megaMenuLevels.forEach(megaMenuLevel => {
      megaMenuLevel.addEventListener('keydown', (event) => {
        if (event.key === 'Tab' && !event.shiftKey) {
          const focusableElements = megaMenuLevel.querySelectorAll('a, button, [tabindex]:not([tabindex="-1"])');
          const lastFocusableElement = focusableElements[focusableElements.length - 1];
          if (document.activeElement === lastFocusableElement) {
            event.preventDefault();
            openMenuCtrl.focus();
          }
        }
      });
    });
  }

  initLangDropdowns() {
    const langDropdowns = document.querySelectorAll('.c-header-navigation__lang-dropdown');
    const homePage = document.querySelector('.default');

    const langMapping = {
      'early-careers': [
        { lang: 'en', url: '/career-journeys/early-careers/', text: 'English (US)' }
      ],
      'early-careers-jobs': [
        { lang: 'en', url: '/career-journeys/early-careers/jobs', text: 'English (US)' }
      ],
      'early-careers--apprenticeships': [
        { lang: 'en', url: '/career-journeys/early-careers/apprenticeships/jobs?keyword="apprentice"', text: 'English (US)' }
      ],
      'early-careers--hotel-internship-program': [
        { lang: 'en', url: '/career-journeys/early-careers/hotel-internship-program/jobs?keyword="intern"', text: 'English (US)' }
      ],
      'early-careers--HQ-fellowship-Internship-program': [
        { lang: 'en', url: '/career-journeys/early-careers/HQ-fellowship-Internship-program/jobs', text: 'English (US)' }
      ],
      'early-careers--voyage-leadership-development-program': [
        { lang: 'en', url: '/career-journeys/early-careers/voyage-leadership-development-program/jobs?keyword="voyage"', text: 'English (US)' }
      ],

      'veterans': [
        { lang: 'en', url: '/career-journeys/veterans/', text: 'English (US)' }
      ],

      'refugees': [
        { lang: 'en', url: '/career-journeys/refugees/', text: 'English (US)' },
        { lang: 'es', url: '/es/career-journeys/refugees/', text: 'Español' },
        { lang: 'prs', url: '/prs/career-journeys/refugees/', text: 'Dari (PRS)' },
        { lang: 'ps', url: '/ps/career-journeys/refugees/', text: 'Pashto' },
        { lang: 'uk', url: '/uk/career-journeys/refugees/', text: 'Ukrainian' },
        { lang: 'fa', url: '/fa/career-journeys/refugees/', text: 'Farsi' }
      ],

      'refugees-jobs': [
        { lang: 'en', url: '/career-journeys/refugees/jobs', text: 'English (US)' },
        { lang: 'es', url: '/es/career-journeys/refugees/jobs', text: 'Español' },
        { lang: 'prs', url: '/prs/career-journeys/refugees/jobs', text: 'Dari (PRS)' },
        { lang: 'ps', url: '/ps/career-journeys/refugees/jobs', text: 'Pashto' },
        { lang: 'uk', url: '/uk/career-journeys/refugees/jobs', text: 'Ukrainian' },
        { lang: 'fa', url: '/fa/career-journeys/refugees/jobs', text: 'Farsi' }
      ],

       'talent-flex': [
        { lang: 'en', url: '/career-journeys/flex-talent-solutions/', text: 'English (US)' }
      ],

      'talent-flex-jobs': [
        { lang: 'en', url: '/career-journeys/flex-talent-solutions/jobs', text: 'English (US)' }
      ],
    
      'st.regis': [
        { lang: 'en', url: '/brands/st-regis/', text: 'English (US)' },
        { lang: 'ar', url: '/ar/brands/st-regis/', text: 'العربية' },
        { lang: 'es', url: '/es/brands/st-regis/', text: 'Español' },
        { lang: 'it', url: '/it/brands/st-regis/', text: 'Italiano' },
        { lang: 'zh-cn', url: '/zh-cn/brands/st-regis/', text: '中文 简体 (中国)' }
      ],

      'st.regis-jobs': [
        { lang: 'en', url: '/brands/st-regis/jobs', text: 'English (US)' },
        { lang: 'ar', url: '/ar/brands/st-regis/jobs', text: 'العربية' },
        { lang: 'es', url: '/es/brands/st-regis/jobs', text: 'Español' },
        { lang: 'it', url: '/it/brands/st-regis/jobs', text: 'Italiano' },
        { lang: 'zh-cn', url: '/zh-cn/brands/st-regis/jobs', text: '中文 简体 (中国)' }
      ],

      'w-hotel': [
        { lang: 'en', url: '/brands/w-hotels/', text: 'English (US)' },
        { lang: 'ar', url: '/ar/brands/w-hotels/', text: 'العربية' },
        { lang: 'es', url: '/es/brands/w-hotels/', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/w-hotels/', text: 'Français' },
        { lang: 'zh-cn', url: '/zh-cn/brands/w-hotels/', text: '中文 简体 (中国)' }
      ],

      'w-hotel-jobs': [
        { lang: 'en', url: '/brands/w-hotels/jobs', text: 'English (US)' },
        { lang: 'ar', url: '/ar/brands/w-hotels/jobs', text: 'العربية' },
        { lang: 'es', url: '/es/brands/w-hotels/jobs', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/w-hotels/jobs', text: 'Français' },
        { lang: 'zh-cn', url: '/zh-cn/brands/w-hotels/jobs', text: '中文 简体 (中国)' }
      ],

      'marriott-hotels': [
        { lang: 'en', url: '/brands/marriott-hotels/', text: 'English (US)' },
        { lang: 'ar', url: '/ar/brands/marriott-hotels/', text: 'العربية' },
        { lang: 'es', url: '/es/brands/marriott-hotels/', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/marriott-hotels/', text: 'Français' }
      ],
      'marriott-hotels-jobs': [
        { lang: 'en', url: '/brands/marriott-hotels/jobs', text: 'English (US)' },
        { lang: 'ar', url: '/ar/brands/marriott-hotels/jobs', text: 'العربية' },
        { lang: 'es', url: '/es/brands/marriott-hotels/jobs', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/marriott-hotels/jobs', text: 'Français' }
      ],

      'bulgari': [
        { lang: 'en', url: '/brands/bulgari/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/bulgari/', text: '中文 简体 (中国)' },
        { lang: 'fr', url: '/fr/brands/bulgari/', text: 'Français' },
        { lang: 'it', url: '/it/brands/bulgari/', text: 'Italiano' },
        { lang: 'ja', url: '/ja/brands/bulgari/', text: '日本語 (日本)' },
        { lang: 'ko', url: '/ko/brands/bulgari/', text: '한국어 (대한민국)' },
        { lang: 'ru', url: '/ru/brands/bulgari/', text: 'Русский' },
        { lang: 'ar', url: '/ar/brands/bulgari/', text: 'العربية' }
      ],

      'bulgari-jobs': [
        { lang: 'en', url: '/brands/bulgari/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/bulgari/jobs', text: '中文 简体 (中国)' },
        { lang: 'fr', url: '/fr/brands/bulgari/jobs', text: 'Français' },
        { lang: 'it', url: '/it/brands/bulgari/jobs', text: 'Italiano' },
        { lang: 'ja', url: '/ja/brands/bulgari/jobs', text: '日本語 (日本)' },
        { lang: 'ko', url: '/ko/brands/bulgari/jobs', text: '한국어 (대한민국)' },
        { lang: 'ru', url: '/ru/brands/bulgari/jobs', text: 'Русский' },
        { lang: 'ar', url: '/ar/brands/bulgari/jobs', text: 'العربية' }
      ],

      'ac-hotels': [
        { lang: 'en', url: '/brands/ac-hotels/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/ac-hotels/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/ac-hotels/', text: 'Español' },
        { lang: 'ar', url: '/ar/brands/ac-hotels/', text: 'العربية' }
      ],
      'ac-hotels-jobs': [
        { lang: 'en', url: '/brands/ac-hotels/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/ac-hotels/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/ac-hotels/jobs', text: 'Español' },
        { lang: 'ar', url: '/ar/brands/ac-hotels/jobs', text: 'العربية' }
      ],


      'moxy': [
        { lang: 'en', url: '/brands/moxy-hotels/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/moxy-hotels/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/moxy-hotels/', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/moxy-hotels/', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/moxy-hotels/', text: 'العربية' }
      ],
      'moxy-jobs': [
        { lang: 'en', url: '/brands/moxy-hotels/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/moxy-hotels/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/moxy-hotels/jobs', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/moxy-hotels/jobs', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/moxy-hotels/jobs', text: 'العربية' }
      ],


      'luxury': [
        { lang: 'en', url: '/brands/the-luxury-collection/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/the-luxury-collection/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/the-luxury-collection/', text: 'Español' },
        { lang: 'it', url: '/it/brands/the-luxury-collection/', text: 'Italiano' },
        { lang: 'ar', url: '/ar/brands/the-luxury-collection/', text: 'العربية' }
      ],
      'luxury-jobs': [
        { lang: 'en', url: '/brands/the-luxury-collection/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/the-luxury-collection/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/the-luxury-collection/jobs', text: 'Español' },
        { lang: 'it', url: '/it/brands/the-luxury-collection/jobs', text: 'Italiano' },
        { lang: 'ar', url: '/ar/brands/the-luxury-collection/jobs', text: 'العربية' }
      ],


      'westin': [
        { lang: 'en', url: '/brands/westin/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/westin/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/westin/', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/westin/', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/westin/', text: 'العربية' }
      ],
      'westin-jobs': [
        { lang: 'en', url: '/brands/westin/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/westin/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/westin/jobs', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/westin/jobs', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/westin/jobs', text: 'العربية' }
      ],


      'jw-marriott': [
        { lang: 'en', url: '/brands/jw-marriott/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/jw-marriott/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/jw-marriott/', text: 'Español' }
      ],
      'jw-marriott-jobs': [
        { lang: 'en', url: '/brands/jw-marriott/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/jw-marriott/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/jw-marriott/jobs', text: 'Español' }
      ],



      'ritz-carlton': [
        { lang: 'en', url: '/brands/the-ritz-carlton/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/the-ritz-carlton/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/the-ritz-carlton/', text: 'Español' },
        { lang: 'ja', url: '/ja/brands/the-ritz-carlton/', text: '日本語 (日本)' },
        { lang: 'ar', url: '/ar/brands/the-ritz-carlton/', text: 'العربية' }
      ],
      'ritz-carlton-jobs': [
        { lang: 'en', url: '/brands/the-ritz-carlton/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/the-ritz-carlton/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/the-ritz-carlton/jobs', text: 'Español' },
        { lang: 'ja', url: '/ja/brands/the-ritz-carlton/jobs', text: '日本語 (日本)' },
        { lang: 'ar', url: '/ar/brands/the-ritz-carlton/jobs', text: 'العربية' }
      ],


      'renaissance': [
        { lang: 'en', url: '/brands/renaissance-hotels/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/renaissance-hotels/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/renaissance-hotels/', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/renaissance-hotels/', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/renaissance-hotels/', text: 'العربية' }
      ],
      'renaissance-jobs': [
        { lang: 'en', url: '/brands/renaissance-hotels/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/renaissance-hotels/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/renaissance-hotels/jobs', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/renaissance-hotels/jobs', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/renaissance-hotels/jobs', text: 'العربية' }
      ],


      'sheraton': [
        { lang: 'en', url: '/brands/sheraton-hotel/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/sheraton-hotel/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/sheraton-hotel/', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/sheraton-hotel/', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/sheraton-hotel/', text: 'العربية' }
      ],
      'sheraton-jobs': [
        { lang: 'en', url: '/brands/sheraton-hotel/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/sheraton-hotel/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/sheraton-hotel/jobs', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/sheraton-hotel/jobs', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/sheraton-hotel/jobs', text: 'العربية' }
      ],


      'le-meridien': [
        { lang: 'en', url: '/brands/le-meridien/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/le-meridien/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/le-meridien/', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/le-meridien/', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/le-meridien/', text: 'العربية' }
      ],
      'le-meridien-jobs': [
        { lang: 'en', url: '/brands/le-meridien/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/le-meridien/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/le-meridien/jobs', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/le-meridien/jobs', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/le-meridien/jobs', text: 'العربية' }
      ],



      'aloft': [
        { lang: 'en', url: '/brands/aloft/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/aloft/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/aloft/', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/aloft/', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/aloft/', text: 'العربية' }
      ],
      'aloft-jobs': [
        { lang: 'en', url: '/brands/aloft/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/aloft/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/aloft/jobs', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/aloft/jobs', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/aloft/jobs', text: 'العربية' }
      ],



      'element': [
        { lang: 'en', url: '/brands/element/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/element/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/element/', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/element/', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/element/', text: 'العربية' }
      ],
      'element-jobs': [
        { lang: 'en', url: '/brands/element/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/element/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/element/jobs', text: 'Español' },
        { lang: 'fr', url: '/fr/brands/element/jobs', text: 'Français' },
        { lang: 'ar', url: '/ar/brands/element/jobs', text: 'العربية' }
      ],


      'edition': [
        { lang: 'en', url: '/brands/edition/', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/edition/', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/edition/', text: 'Español' },
        { lang: 'it', url: '/it/brands/edition/', text: 'Italiano' }
      ],
      'edition-jobs': [
        { lang: 'en', url: '/brands/edition/jobs', text: 'English (US)' },
        { lang: 'zh-cn', url: '/zh-cn/brands/edition/jobs', text: '中文 简体 (中国)' },
        { lang: 'es', url: '/es/brands/edition/jobs', text: 'Español' },
        { lang: 'it', url: '/it/brands/edition/jobs', text: 'Italiano' }
      ],


      'delta': [
        { lang: 'en', url: '/brands/delta-hotels/', text: 'English (US)' }
       
      ],
      'delta-jobs': [
        { lang: 'en', url: '/brands/delta-hotels/jobs', text: 'English (US)' }
      ],



      'gaylord': [
        { lang: 'en', url: '/brands/gaylord-hotels/', text: 'English (US)' }
       
      ],
      'gaylord-jobs': [
        { lang: 'en', url: '/brands/gaylord-hotels/jobs', text: 'English (US)' }
      ],


      'fairfield': [
        { lang: 'en', url: '/brands/fairfield/', text: 'English (US)' }
       
      ],
      'fairfield-jobs': [
        { lang: 'en', url: '/brands/fairfield/jobs', text: 'English (US)' }
      ],



      'courtyard': [
        { lang: 'en', url: '/brands/courtyard/', text: 'English (US)' }
       
      ],
      'courtyard-jobs': [
        { lang: 'en', url: '/brands/courtyard/jobs', text: 'English (US)' }
      ],



      'fourpoints': [
        { lang: 'en', url: '/brands/four-points/', text: 'English (US)' }
       
      ],
      'fourpoints-jobs': [
        { lang: 'en', url: '/brands/four-points/jobs', text: 'English (US)' }
      ],


      'residence-inn': [
        { lang: 'en', url: '/brands/residence-inn/', text: 'English (US)' }
       
      ],
      'residence-inn-jobs': [
        { lang: 'en', url: '/brands/residence-inn/jobs', text: 'English (US)' }
      ],

      'springhill-suits': [
        { lang: 'en', url: '/brands/springhill-suits/', text: 'English (US)' }
       
      ],
      'springhill-suits-jobs': [
        { lang: 'en', url: '/brands/springhill-suits/jobs', text: 'English (US)' }
      ],


      'towneplace-suits': [
        { lang: 'en', url: '/brands/towneplace-suites/', text: 'English (US)' }
       
      ],
      'towneplace-suits-jobs': [
        { lang: 'en', url: '/brands/towneplace-suites/jobs', text: 'English (US)' }
      ],



      'protea': [
        { lang: 'en', url: '/brands/protea/', text: 'English (US)' }
       
      ],
      'protea-jobs': [
        { lang: 'en', url: '/brands/protea/jobs', text: 'English (US)' }
      ],


      'tribute': [
        { lang: 'en', url: '/brands/tribute-portfolio/', text: 'English (US)' }
       
      ],
      'tribute-jobs': [
        { lang: 'en', url: '/brands/tribute-portfolio/jobs', text: 'English (US)' }
      ],


      'autograph': [
        { lang: 'en', url: '/brands/autograph-collection/', text: 'English (US)' }
       
      ],
      'autograph-jobs': [
        { lang: 'en', url: '/brands/autograph-collection/jobs', text: 'English (US)' }
      ],



      'design': [
        { lang: 'en', url: '/brands/design-hotels/', text: 'English (US)' }
       
      ],
      'design-jobs': [
        { lang: 'en', url: '/brands/design-hotels/jobs', text: 'English (US)' }
      ],


      'cityexpress': [
        { lang: 'en', url: '/brands/city-express/', text: 'English (US)' }
       
      ],
      'cityexpress-jobs': [
        { lang: 'en', url: '/brands/city-express/jobs', text: 'English (US)' }
      ],

      'marriott-executive': [
        { lang: 'en', url: '/brands/marriott-executive-apartments/', text: 'English (US)' }
       
      ],
      'marriott-executive-jobs': [
        { lang: 'en', url: '/brands/marriott-executive-apartments/jobs', text: 'English (US)' }
      ],

      'four-points-express-jobs': [
        { lang: 'en', url: '/brands/four-points-express/jobs', text: 'English (US)' }
      ],

      'marriott-vacation-club-jobs': [
        { lang: 'en', url: '/brands/marriott-vacation-club/jobs', text: 'English (US)' }
      ],

      'apartments-by-marriott-jobs': [
        { lang: 'en', url: '/brands/apartments-by-marriott/jobs', text: 'English (US)' }
      ],


    };


    langDropdowns.forEach(langDropdown => {
      const langBtn = langDropdown.querySelector('.c-header-navigation__lang-dropdown--btn');
      const langBtnText = langBtn.querySelector('.c-header-navigation__lang-dropdown--btn-text');
      const langContent = langDropdown.querySelector('.c-header-navigation__lang-dropdown--content');
      const langLinks = langContent.querySelectorAll('li');

      const toggleLangContent = () => {
        langContent.classList.toggle('show');
        const expanded = langBtn.getAttribute('aria-expanded') === 'true';
        langBtn.setAttribute('aria-expanded', !expanded);
      };

      langContent.addEventListener('keydown', (event) => {
        if (event.key === 'Tab' && !event.shiftKey) {
          const focusableElements = langContent.querySelectorAll('a, button, [tabindex]:not([tabindex="-1"])');
          const lastFocusableElement = focusableElements[focusableElements.length - 1];
          if (document.activeElement === lastFocusableElement) {
            event.preventDefault();
            langContent.classList.remove('show');
          }
        }
      });

      langBtn.addEventListener('click', toggleLangContent);

      document.addEventListener('click', event => {
        const target = event.target;
        if (target !== langBtn && !langDropdown.contains(target)) {
          langContent.classList.remove('show');
        }
      });

      const mainElement = document.querySelector('main'); // Select the main element
      for (const page in langMapping) {
        if (mainElement.getAttribute('data-page') === page) {
          this.updateLangDropdown(langContent, langMapping[page]);
          break;
        }
      }


      if (!document.querySelector('[data-page="job-detail"]') && !document.querySelector('[data-page="404"]')) {
        if (!this.hasDataLangValuesInPath()) {
          const langLinkEn = langContent.querySelector('li a[data-lang="en"]');
          this.toggleLangLinkActive(langLinkEn.parentElement, true);

          const currentPath = window.location.pathname;
          langLinks.forEach(langLink => {
            const langCode = langLink.querySelector('a').getAttribute('data-lang');
            langLink.querySelector('a').setAttribute('href', '/' + `${langCode}${currentPath}`);
          });
        } else {
          const currentPath = window.location.pathname;
          const endsWithSlash = currentPath.endsWith('/') && !homePage;
          const currentPathSegments = currentPath.split('/').filter(segment => segment !== '');
          const newPath = currentPathSegments.slice(1).join('/') + (endsWithSlash ? '/' : '');

          langLinks.forEach(langLink => {
            const langCode = langLink.querySelector('a').getAttribute('data-lang');
            langLink.querySelector('a').setAttribute('href', `/${langCode}/${newPath}`);
            if (this.isLangCodeInPath(langCode)) {
              console.log("🚀 ~ extends ~ initLangDropdowns ~ this.isLangCodeInPath(langCode):", this.isLangCodeInPath(langCode));
              this.toggleLangLinkActive(langLink, true);
            }
          });
        }
      }


      // const currentUrl = window.location.href;

      // function normalizeUrl(url) {
      //   return url.endsWith("/") ? url : url + "/";
      // }

      // function getLanguageFromUrl(url) {
      //   const languageRegex = /\/([a-z]{2}(?:-[a-z]{2})?)\//;
      //   const match = url.match(languageRegex);
      //   return match ? match[1] : null;
      // }

      // const normalizedCurrentUrl = normalizeUrl(currentUrl);
      // const langLinksAll = document.querySelectorAll(".c-header-navigation__lang-dropdown--content a");

      // const languageFromUrl = getLanguageFromUrl(normalizedCurrentUrl);

      // if (languageFromUrl) {
      //   let matchedLangText = null;

       
      //   langLinksAll.forEach(function (link) {
      //     const langCode = link.getAttribute("data-lang");

      //     if (languageFromUrl === langCode) {
      //       matchedLangText = link.textContent;
      //     } else if (languageFromUrl.startsWith(langCode + '-') && !matchedLangText) {
      //       matchedLangText = link.textContent;
      //     }
      //   });

      //   if (matchedLangText) {
      //     document.querySelectorAll(".c-header-navigation__lang-dropdown--btn-text").forEach(function (btnText) {
      //       btnText.textContent = matchedLangText;
      //     });
      //   }
      // }



    });
  }

  updateLangDropdown(langContent, languages) {
    langContent.innerHTML = '';
    languages.forEach(language => {
      const li = document.createElement('li');
      const a = document.createElement('a');
      a.setAttribute('data-lang', language.lang);
      a.setAttribute('href', language.url);
      a.textContent = language.text || language.lang;
      li.appendChild(a);
      langContent.appendChild(li);
    });
    
  }

  hasDataLangValuesInPath() {
    const langLinks = document.querySelectorAll('.c-header-navigation__lang-dropdown--content li');
    const pathSegments = window.location.pathname.split('/').filter(segment => segment !== ''); // Exclude empty segments

    return Array.from(langLinks).some(langLink => {
      const langCode = langLink.querySelector('a').getAttribute('data-lang');
      return pathSegments.includes(langCode);
    });
  }

  isLangCodeInPath(langCode) {
    const pathSegments = window.location.pathname.split('/').filter(segment => segment !== '');
    return pathSegments.includes(langCode);
  }

  toggleLangLinkActive(langLink, isActive) {
    if (isActive) {
      langLink.classList.add('active');
    } else {
      langLink.classList.remove('active');
    }
  }

  ADANavigation() {
    // Get all dropdown buttons and menus
    const dropdownButtons = document.querySelectorAll('.c-header-navigation__link.has-drop');
    const dropdownMenus = document.querySelectorAll('.c-header-navigation__item-submenu');

    // Function to toggle dropdown menu visibility
    const toggleDropdownMenu = (dropdownButton, dropdownMenu) => {
      const expanded = dropdownButton.getAttribute('aria-expanded') === 'true';
      // Toggle aria-expanded attribute
      dropdownButton.setAttribute('aria-expanded', !expanded);
      // Toggle dropdown menu visibility
      dropdownMenu.classList.toggle('show');
      // Toggle 'show' class on the parent node of dropdownButton
      dropdownButton.parentNode.classList.toggle('show');
    };

    // Iterate over each dropdown button and attach event listeners
    dropdownButtons.forEach((dropdownButton, index) => {
      const dropdownMenu = dropdownMenus[index];

      // Toggle dropdown menu visibility when button is clicked
      dropdownButton.addEventListener('click', () => {
        toggleDropdownMenu(dropdownButton, dropdownMenu);
      });

      // Handle keydown event for accessibility
      dropdownButton.addEventListener('keydown', (event) => {
        // Check if Enter key is pressed
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent default action
          toggleDropdownMenu(dropdownButton, dropdownMenu); // Toggle dropdown menu visibility
        }
      });

      // Close dropdown menu when last focusable element inside dropdown menu loses focus
      dropdownMenu.addEventListener('keydown', (event) => {
        if (event.key === 'Tab' && !event.shiftKey) {
          const focusableElements = dropdownMenu.querySelectorAll('a, button, [tabindex]:not([tabindex="-1"])');
          const lastFocusableElement = focusableElements[focusableElements.length - 1];
          if (document.activeElement === lastFocusableElement) {
            event.preventDefault();
            dropdownButton.setAttribute('aria-expanded', 'false');
            dropdownMenu.classList.remove('show');
            // Remove 'show' class from the parent node of dropdownButton
            dropdownButton.parentNode.classList.remove('show');
            const nextNavItem = dropdownButton.closest('.c-header-navigation__item').nextElementSibling;
            if (nextNavItem && nextNavItem.classList.contains('c-header-navigation__item')) {
              const nextNavItemFocusableElement = nextNavItem.querySelector('a, button, [tabindex]:not([tabindex="-1"])');
              if (nextNavItemFocusableElement) {
                nextNavItemFocusableElement.focus(); // Move focus to the first focusable element of the next navigation item
              }
            }
          }
        }
      });
    });

    // Close dropdown menus when clicking outside of them
    document.addEventListener('click', (event) => {
      dropdownButtons.forEach((dropdownButton, index) => {
        const dropdownMenu = dropdownMenus[index];
        if (!dropdownButton.contains(event.target) && !dropdownMenu.contains(event.target)) {
          dropdownButton.setAttribute('aria-expanded', 'false');
          dropdownMenu.classList.remove('show');
          // Remove 'show' class from the parent node of dropdownButton
          dropdownButton.parentNode.classList.remove('show');
        }
      });
    });
  }

  // configureBreadcrumbs() {
  //   const path = location.href.split('/').slice(3);
  //   const linkPaths = [{ "main": 'FedEx Careers', "link": '/' }];

  //   path.forEach((component, i) => {
  //     const anchorText = decodeURIComponent(component).toUpperCase().split('.')[0];
  //     const link = '/' + path.slice(0, i + 1).join('/');
  //     linkPaths.push({ "main": anchorText, "link": link });
  //   });

  //   const values = linkPaths.map(part => `<a href="${part.link}">${part.main}</a>`).join('<span style="margin:0 10px"> <svg><use xlink:href="#svg-slider-arrow-r"></use></svg></span>`);

  //   const element = document.getElementById("breadcrumb");
  //   element.innerHTML = values;
  // }

  destroy() {
    console.log("❌ [module]:destroy - Navigation");
    super.destroy();
    this.$el.off(`.${EVENT_NAMESPACE}`);
  }
}
