import { module as BaseModule } from 'modujs';
import { gsap } from 'gsap';

export default class extends BaseModule {
  constructor(module) {
    super(module);
  }

  init() {
    const ctaBoxes = document.querySelectorAll('.c-cta-box__main');

    ctaBoxes.forEach((box) => {
      const video = box.querySelector('video');
      
      box.addEventListener('mouseover', () => {
        video.play();
      });

      box.addEventListener('mouseout', () => {
        video.pause();
        video.currentTime = 0; 
      });
    });
  }

  destroy() {
    super.destroy();
  }
}
